import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { McCoreModule } from '@miticon-ui/mc-core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { McAddCustomAttributeComponent } from './mc-add-custom-attribute/mc-add-custom-attribute.component';
import { McAmountDueComponent } from './mc-amount-due/mc-amount-due.component';
import { McBackToLoginComponent } from './mc-back-to-login/mc-back-to-login.component';
import { McBankAccountSelectAlternateComponent } from './mc-bank-account-select-alternate/mc-bank-account-select-alternate.component';
import { McComponentsComponent } from './mc-components.component';
import { McChooseMappingComponent } from './mc-csv-columns-mapping/mc-choose-mapping/mc-choose-mapping.component';
import { McCsvColumnsMappingComponent } from './mc-csv-columns-mapping/mc-csv-columns-mapping.component';
import { McJobActionModule } from './mc-job-action/mc-job-action.module';
import { McLoaderComponent } from './mc-loader/mc-loader.component';
import { McMenuComponent } from './mc-menu/mc-menu.component';
import { McNotifyMessageComponent } from './mc-notify-message/mc-notify-message.component';
import { McSeePasswordComponent } from './mc-see-password/mc-see-password.component';
import { McStepperComponent } from './mc-stepper/mc-stepper.component';
import { MccButtonComponent } from './mcc-button/mcc-button.component';
import { MccDialogBoxComponent } from './mcc-dialog-box/mcc-dialog-box.component';
import { MccDynamicComponentGeneratorComponent } from './mcc-dynamic-component-generator/mcc-dynamic-component-generator.component';
import { MccFiCheckboxComponent } from './mcc-fi-checkbox/mcc-fi-checkbox.component';
import { MccFiCheckboxesComponent } from './mcc-fi-checkboxes/mcc-fi-checkboxes.component';
import { MccFiCustomAttributeComponent } from './mcc-fi-custom-attribute/mcc-fi-custom-attribute.component';
import { MccFiDatePickerComponent } from './mcc-fi-date-picker/mcc-fi-date-picker.component';
import { MccFiFileUploadModule } from './mcc-fi-file-upload/mcc-fi-file-upload.module';
import { MccFiFileUpload2Component } from './mcc-fi-file-upload2/mcc-fi-file-upload2.component';
import { MccFiItemlistCheckboxesComponent } from './mcc-fi-itemlist-checkboxes/mcc-fi-itemlist-checkboxes.component';
import { MccFiMultiselect2Component } from './mcc-fi-multiselect2/mcc-fi-multiselect2.component';
import { MccFiNumberTypeComponent } from './mcc-fi-number-type/mcc-fi-number-type.component';
import { MccFiRadioButtonsComponent } from './mcc-fi-radio-buttons/mcc-fi-radio-buttons.component';
import { MccFiRadioButtons2Component } from './mcc-fi-radio-buttons2/mcc-fi-radio-buttons2.component';
import { MccFiSelectOptionComponent } from './mcc-fi-select-option/mcc-fi-select-option.component';
import { MccFiSelectComponent } from './mcc-fi-select/mcc-fi-select.component';
import { MccFiSubmitComponent } from './mcc-fi-submit/mcc-fi-submit.component';
import { McTableConfigurationModalComponent } from './mcc-fi-table-2/components/mc-table-configuration-modal/mc-table-configuration-modal.component';
import { MccFiTable2Component } from './mcc-fi-table-2/mcc-fi-table-2.component';
import { McTable3ConfigurationModalComponent } from './mcc-fi-table-3/components/mc-table-3-configuration-modal/mc-table-3-configuration-modal.component';
import { MccFiTable3Component } from './mcc-fi-table-3/mcc-fi-table-3.component';
import { MccFiTextareaWithTokenComponent } from './mcc-fi-textarea-with-token/mcc-fi-textarea-with-token.component';
import { MccFiTextareaComponent } from './mcc-fi-textarea/mcc-fi-textarea.component';
import { MccFiTextboxWithTokenComponent } from './mcc-fi-textbox-with-token/mcc-fi-textbox-with-token.component';
import { MccFiTextboxComponent } from './mcc-fi-textbox/mcc-fi-textbox.component';
import { MccHtmlActionSetComponent } from './mcc-html-action-set/mcc-html-action-set.component';
import { SafeHtmlPipe } from './mcc-html/mc-html-safe.pipe';
import { MccHtmlComponent } from './mcc-html/mcc-html.component';
import { MccInfoLabelCardComponent } from './mcc-info-label-card/mcc-info-label-card.component';
import { MccMcFormMessageComponent } from './mcc-mc-form-message/mcc-mc-form-message.component';
import { MccMenuComponent } from './mcc-menu/mcc-menu.component';
import { MccMessageComponent } from './mcc-message/mcc-message.component';
import { MccModal1WrapperComponent } from './mcc-modal1-wrapper/mcc-modal1-wrapper.component';
import { MccMultiselectFilterComponent } from './mcc-multiselect-filter/mcc-multiselect-filter.component';
import { MccPaginationComponent } from './mcc-pagination/mcc-pagination.component';
import { MccRowTitleDescriptionComponent } from './mcc-row-title-description/mcc-row-title-description.component';
import { MccSlideActionSetComponent } from './mcc-slide-action-set/mcc-slide-action-set.component';
import { MccStepperComponent } from './mcc-stepper/mcc-stepper.component';
import { MkMatTableModule } from './mk-mat-table/mk-mat-table.module';
import { SafeHtmlModule } from './safe-html/safe-html.module';
import { UploadFileModule } from './upload-file/upload-file.module';

@NgModule({
  declarations: [
    McComponentsComponent,
    McNotifyMessageComponent,
    McSeePasswordComponent,
    McBackToLoginComponent,
    McStepperComponent,
    McLoaderComponent,
    McAmountDueComponent,
    MccFiTextboxComponent,
    MccFiSubmitComponent,
    MccFiTable2Component,
    MccFiTable3Component,
    MccFiSelectOptionComponent,
    MccDynamicComponentGeneratorComponent,
    McCsvColumnsMappingComponent,
    McChooseMappingComponent,
    MccFiRadioButtonsComponent,
    McAddCustomAttributeComponent,
    McBankAccountSelectAlternateComponent,
    MccFiDatePickerComponent,
    McTableConfigurationModalComponent,
    MccFiSelectComponent,
    MccFiCheckboxComponent,
    MccFiCheckboxesComponent,
    MccHtmlComponent,
    SafeHtmlPipe,
    MccPaginationComponent,
    McMenuComponent,
    MccFiItemlistCheckboxesComponent,
    MccFiTextareaComponent,
    MccHtmlActionSetComponent,
    MccRowTitleDescriptionComponent,
    MccModal1WrapperComponent,
    MccFiCustomAttributeComponent,
    MccMessageComponent,
    MccButtonComponent,
    MccFiRadioButtons2Component,
    MccDialogBoxComponent,
    MccMcFormMessageComponent,
    MccMenuComponent,
    MccFiNumberTypeComponent,
    MccSlideActionSetComponent,
    MccFiTextareaWithTokenComponent,
    MccFiTextboxWithTokenComponent,
    MccFiFileUpload2Component,
    McTable3ConfigurationModalComponent,
    MccMultiselectFilterComponent,
    MccFiMultiselect2Component,
    ConfirmationDialogComponent,
    MccInfoLabelCardComponent,
    MccStepperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    CdkStepperModule,
    McJobActionModule,
    TranslateModule,
    McCoreModule,
    NgSelectModule,
    MkMatTableModule,
    MccFiFileUploadModule,
    UploadFileModule,
    MatTooltipModule,
    SafeHtmlModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatButtonModule,
    SafeHtmlModule,
  ],
  exports: [
    McComponentsComponent,
    McNotifyMessageComponent,
    McSeePasswordComponent,
    McBackToLoginComponent,
    McStepperComponent,
    McLoaderComponent,
    McAmountDueComponent,
    MccFiTextboxComponent,
    MccFiSubmitComponent,
    MccFiTable2Component,
    MccFiTable3Component,
    MccDynamicComponentGeneratorComponent,
    MccFiSelectOptionComponent,
    McCsvColumnsMappingComponent,
    McChooseMappingComponent,
    MccFiRadioButtonsComponent,
    McBankAccountSelectAlternateComponent,
    MccFiDatePickerComponent,
    MccFiSelectComponent,
    MccFiCheckboxComponent,
    MccFiCheckboxesComponent,
    MccHtmlComponent,
    SafeHtmlPipe,
    MccPaginationComponent,
    McAddCustomAttributeComponent,
    McMenuComponent,
    MccFiItemlistCheckboxesComponent,
    MccFiTextareaComponent,
    MccHtmlActionSetComponent,
    MccRowTitleDescriptionComponent,
    MccModal1WrapperComponent,
    MccFiCustomAttributeComponent,
    MccMessageComponent,
    MccButtonComponent,
    MccFiRadioButtons2Component,
    MccDialogBoxComponent,
    MccMcFormMessageComponent,
    MccMenuComponent,
    MccFiNumberTypeComponent,
    MccSlideActionSetComponent,
    MccFiTextareaWithTokenComponent,
    MccFiTextboxWithTokenComponent,
    MccFiFileUpload2Component,
    MccMultiselectFilterComponent,
    MccFiMultiselect2Component,
    ConfirmationDialogComponent,
    MccInfoLabelCardComponent,
    MccStepperComponent,
    MkMatTableModule,
    MccFiFileUploadModule,
    UploadFileModule,
  ],
  entryComponents: [MccFiSelectOptionComponent, MccDynamicComponentGeneratorComponent],
})
export class McComponentsModule {}
